<template>
    <section class="text-center user-info-section" style="padding-top: 200px; padding-bottom: 80px;">
        <b-container>
            <h1 class="text-uppercase header-404">404.</h1>
            <p class="lead text-muted text-uppercase">{{ $t('message.error_404.page_not_found') }}</p>
            <b-row class="justify-content-md-center">
                <b-col md="6">
                <p style="margin-bottom: 20px;">
                    {{ $t('message.error_404.paragraph') }}
                </p>
                    <div class="text-center">
                        <router-link class="btn btn-primary round-xl btn btn-xs" :to="{ name: $i18n.locale + '_home' }">
                            <span class="fa fa-home"></span> {{ $t('message.error_404.back_to_homepage') }}
                        </router-link>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </section>
</template>

<script>
    export default {
        name: "error404"
    }
</script>

<style scoped>

</style>
